<template>
  <v-row
    class="fill-height"
    justify="center"
    align="center"
  >
    <v-col
      :lg="4"
      class="px-10 lg:px-0"
    >
      <v-card>
        <v-card-title>{{ COMMON.LABEL_FORGOT_PASSWORD }}</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  solo
                  label="Email"
                  placeholder="Enter the email you signed up with"
                  hide-details="auto"
                  type="email"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>

              <v-col cols="12">
                <v-btn
                  block
                  class="primary"
                  type="submit"
                >
                  <span v-if="!isBusy">{{
                    COMMON.LABEL_SEND_RESET_TOKEN
                  }}</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                  />
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-btn
                  text
                  :disabled="isBusy"
                  @click="$router.push('/login')"
                >
                  Login
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      right
      top
    >
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ COMMON.CLOSE_BTN }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import common from '@/commons/common';

export default {
  name: 'ForgotPassword',

  data() {
    return {
      COMMON: common,
      ICON: common.ICON,
      isBusy: false,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 3000,

      email: '',

      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
    };
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.handleSendEmail();
      }
    },

    async handleSendEmail() {
      try {
        this.isBusy = true;
        const { status } = await this.$axios.get(
          `/secured/generateForgotPasswordToken?email=${this.email}`
        );
        if (status === 200)
          this.enableSnackbar('Reset token sent successfull!');
      } catch (error) {
        this.enableSnackbar(error.response.data.message);
        this.$refs.form.reset();
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style></style>
